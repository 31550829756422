import axios from 'axios';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

HTTP.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    if ($helpers.isAuthenticated()) {
      newConfig.headers.Authorization = `Bearer ${$helpers.getApiToken()}`;
    }

    return newConfig;
  },
  (err) => Promise.reject(err),
);

HTTP.interceptors.response.use(
  (res) => {
    const ver = res.headers['app-version'];
    const cached = localStorage.getItem('version');

    if (ver != null) {
      localStorage.setItem('version', ver);
    }

    /* Reload when version is different than cached */

    if (
      process.env.NODE_ENV !== 'local' &&
      ver !== cached &&
      res.config.method == 'get'
    ) {
      window.location.reload();
    }

    return Promise.resolve(res);
  },
  (err) => Promise.reject(err),
);

const corsHTTP = {
  get: (url, params) => HTTP.get(url, { params, withCredentials: true }),
  post: (url, payload, opts) => HTTP.post(
    url,
    payload,
    { withCredentials: true, ...opts },
  ),
  put: (url, payload) => HTTP.put(url, payload, { withCredentials: true }),
  patch: (url, payload) => HTTP.patch(url, payload, { withCredentials: true }),
  delete: (url, params) => HTTP.delete(url, { params, withCredentials: true }),
}

export const $http = corsHTTP;
window.$http = $http;
export const $publicHTTP = HTTP;
window.$publicHTTP = $publicHTTP;
